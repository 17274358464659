<template>
  <div class="vip-page">
    <div class="line"></div>
    <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20221024111735565-vip-equ-bg.png" alt="">
  </div>
</template>
<script>
export default {
  name: 'DaysVip',
  mounted() {
    document.title = 'VIP专属在线权益对比';
    document.body.scrollIntoView();
  },
};
</script>
<style lang="scss" scoped>
.vip-page {
  width: 100%;
  text-align: center;
  .line {
    height: 30px;
    background: #f6f6f6;
  }
  img {
    width: 750px;
  }
}
</style>
<style>
body {
  scroll-behavior: smooth;
}
</style>
